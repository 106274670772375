import {createStore, applyMiddleware, combineReducers} from 'redux';

import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import CustomerListReducer from '../modules/components/customer/store/reducers';


const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk, reduxImmutableStateInvariant()];


export default function configureStore(initialState){
    const store  = createStore(combineReducers({
    customerList:CustomerListReducer,
     
        
    }), composeWithDevTools(applyMiddleware(...middlewares)));

    //sagaMiddleware.run(rootSaga);
    return store;
}