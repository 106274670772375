const removePdf=(documentId,setDocumentLists)=>
{
    
  const items = JSON.parse(sessionStorage.getItem('documentList'));
  
  const filtered = items.filter(item => item.id !== documentId);
  sessionStorage.setItem('documentList', JSON.stringify(filtered));

  setDocumentLists(JSON.parse(sessionStorage.getItem('documentList')));

 
}

export default removePdf;