
////bid TYPE///////////////////////////////////////////////////

export const FETCH_CUSTOMER_REQUEST="FETCH_CUSTOMERREQUEST";
export const FETCH_CUSTOMER_SUCCESS="FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAIL="FETCH_CUSTOMER_FAIL";

///////////////CUSTOMER REDUCER//////////////////////////////
const initialState={
   customerList:[],
    error:'',
    isLoading:false
  }


 ///////////////////////CUSTOMER reducer/////////////////
    export default function CustomerListReducer(state=initialState,action)
    {
      switch(action.type){
        case FETCH_CUSTOMER_SUCCESS:
          return {...state,customerList:action.data,isLoading:true}
      
        case FETCH_CUSTOMER_FAIL:
          return {isLoading:false,customerList:[],error:action.data}
        default:
          return state;
      }
    }