import services from "../services/services";
import { requestObject } from "./requestObject";
var Getstates=(setState)=>
{
    
     //////////////////////////getstate////////////////////////////
           var statereq=
            {
                
            };


               
         // const requestOptions = 
         // {
         //    method: 'POST',
         //    headers: { 'Content-Type': 'application/json'
         //             },
                     
         //       body: JSON.stringify(statereq)
         // };
         var requestOptions=requestObject.RequestHeader(statereq);
         services.getstates(requestOptions).then((res) => 
         {
            setState(res.rows);
         });
    

}


export default Getstates;