import services from "../services/services";
import { requestObject } from "./requestObject";
var GetTankerSubType=(setTankerSubTypes,subType)=>
{
    
     //////////////////////////getstate////////////////////////////

     if(subType=="tanker"){
         var statereq=
         {
                
         };

         var requestOptions=requestObject.RequestHeader(statereq);
         services.getTankerSubTypes(requestOptions).then((res) => 
         {

            setTankerSubTypes(res.rows);
         });
      }else{
         setTankerSubTypes([]);
      }
    

}


export default GetTankerSubType;