import services from "../services/services";
import { requestObject } from "./requestObject";
var Getcontactrole=(setContactRole)=>
{
    
     //////////////////////////getstate////////////////////////////
   

            var statereq=
            {
                  
            };


            var requestOptions=requestObject.RequestHeader(statereq);    
      //    const requestOptions = 
      //    {
      //       method: 'POST',
      //       headers: { 'Content-Type': 'application/json'
      //                },
                     
      //          body: JSON.stringify(statereq)
      //    };

         services.getcontactRoles(requestOptions).then((res) => 
         {
            setContactRole(res.rows);
         });
    

}


export default Getcontactrole;