import React from "react";
import SinglePagePDFViewer from "./singlePage";
const Pdf = () => 
{

      var sampleBase64pdf=localStorage.getItem("pdfbase64");
      
      console.log("sampleBase64pdf="+sampleBase64pdf);
      return (
       
        
          <SinglePagePDFViewer pdf={sampleBase64pdf} />
    

      );
}

Pdf.propTypes = {};
Pdf.defaultProps = {};
export default Pdf;